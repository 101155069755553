<!-- eslint-disable vue/html-indent -->
<template>
  <div>
    <!-- input search -->
    <b-row>
      <b-col>
        <b-form-group
          label="Numéro"
          label-for="Numéro"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="num"
              placeholder="Chercher numéro"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <label for="client-select">Chercher Client</label>
        <v-select
          id="client-select"
          v-model="client"
          :clearable="false"
          placeholder="Client"
          label="company_name"
          :options="clients"
          @input="getFactures(1)"
        />
      </b-col>
      <b-col>
        <label for="status-select">Status</label>
        <v-select
          id="status-select"
          v-model="stausPayment"
          :clearable="false"
          placeholder="Status payment"
          :options="stausPayments"
          label="label"
          @input="getFactures(1)"
        />
      </b-col>
      <b-col class="m-2">
        <b-button
          variant="gradient-info"
          class="ml-1 d-inline-block"
          @click.prevent="resetFilters()"
        >
          <span class="ml-1">Reset Filter</span>
        </b-button>
      </b-col>
      <b-col>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-button
              :to="{ name: 'facture-add' }"
              variant="gradient-primary"
              class="ml-1 d-inline-block"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Ajouter Facture</span>
            </b-button>
          </b-form-group>
        </div></b-col>
    </b-row>
    <div
v-if="isLoading === 'true'"
class="text-center mb-2"
>
      <b-spinner
variant="primary"
label="Text Centered"
/>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="factures"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
      :row-style-class="getRowClass"
    >
      <template
slot="table-row"
slot-scope="props"
>

        <!-- Column: Name -->
        <div
v-if="props.column.field === 'fullName'"
class="text-nowrap"
>
          <b-avatar
:src="props.row.avatar"
class="mx-1"
/>
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
v-if="props.row.status === 'vente direct'"
variant="light-warning"
>
            <span>Vente direct</span>
          </b-badge>
          <b-badge
v-if="props.row.status === 'vente en ligne'"
variant="light-info"
>
            <span>Vente en ligne</span>
          </b-badge>
          <b-badge
v-if="props.row.status === 'vente depot'"
variant="light-info"
>
            <span>Vente depot</span>
          </b-badge>
          <b-badge
v-if="props.row.status === 'vente gros'"
variant="light-success"
>
            <span>Vente Gros</span>
          </b-badge>
        </span>

        <!-- Column: Payment Status -->
        <span v-else-if="props.column.field === 'payment_status'">
          <b-badge
            :variant="getPaymentStatusVariant(props.row.payment_status)"
            class="cursor-pointer"
            @click=" $router.push({ name: 'facture-payment-show', params: { id: props.row.id } })"
            >
            {{ props.row.payment_status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
              @click="deleteFacture(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click=" $router.push({ name: 'facture-show', params: { id: props.row.id } })"
            />
            <b-tooltip
              title="Détail"
              :target="`invoice-row-${props.row.id}-show-icon`"
              />

            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click=" $router.push({ name: 'facture-edit', params: { id: props.row.id } })"
            />
            <b-tooltip
              title="Modifier"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormSelect, BButton, BFormInput,
  BSpinner, BTooltip, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    vSelect,
    BCol,
    BRow,
    BFormInput,
  },
  data() {
    return {
      num: '',
      awaitingSearch: false,
      clients: [],
      client: { id: '' },
      currentPage: 1,
      count: 0,
      isLoading: 'false',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Numéro',
          field: 'reference',
        },
        {
          label: 'Client',
          field: 'customer',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Payment Status',
          field: 'payment_status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      factures: [],
      stausPayments: [
        { value: 'unpaid', label: 'Non payé' },
        { value: 'paid', label: 'Payé' },
        { value: 'partially_paid', label: 'Partiellement payé' },
      ],
      stausPayment: { value: '', label: '' },
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    num() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getFactures(1)
          this.awaitingSearch = false
        }, 1000) // 1 sec delay
      }
      this.awaitingSearch = true
    },
  },
  created() {
    this.getClients()
    this.getFactures(1)
  },
  methods: {
    getPaymentStatusVariant(status) {
      if (status === 'Payé') return 'success'
      if (status === 'Non payé') return 'danger'
      return 'warning'
    },
    async pageNext(currentPage) {
      this.getFactures(currentPage)
    },
    async getClients() {
      this.isLoading = true
      const { data } = await axios.get('/customers/short-list/')
      this.clients = data
      this.isLoading = false
    },
    resetFilters() {
      this.client = { id: '' }
      this.stausPayment = ''
      this.getFactures(1)
    },
    // get factures
    async getFactures(currentPage) {
      this.isLoading = 'true'
      const { data } = await axios.get('/invoices/list/', {
        params: {
          p: currentPage,
          customer: this.client.id,
          payment_status: this.stausPayment.value,
          reference: this.num,
        },
      })
      this.factures = data.results
      this.count = data.count
      this.isLoading = false
    },
    // delete facture
    deleteFacture(devid) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer !',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = 'true'
        if (result.value) {
          axios.delete(`/invoices/delete/${devid}/`).then(() => {
            this.isLoading = 'false'
            this.getFactures(1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Suppression réussite',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    getRowClass(row) {
      if (row.payment_status === 'Payé') {
        return 'table-success'
      } if (row.payment_status === 'Non payé') {
        return 'table-danger'
      } if (row.payment_status === 'Partiellement payé') {
        return 'table-warning'
      }
      return ''
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style scoped>
.custom-modal {
  max-width: 800px; /* Set a custom max-width */
}

.custom-modal .modal-content {
  padding: 20px; /* Add padding for better spacing */
}
.table-success {
  background-color: #d4edda !important;
}
.table-danger {
  background-color: #f8d7da !important;
}
.table-warning {
  background-color: #fff3cd !important;
}
</style>
